import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-2908/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-2908",
  "path": "/Outdoor_Cameras/IN-2908/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-2908 Outdoor IP Camera",
  "image": "../P_SearchThumb_IN-2908HD.png",
  "social": "/images/Search/P_SearchThumb_IN-2908HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-2908HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-2908' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-2908 Outdoor IP Camera' image='/images/Search/P_SearchThumb_IN-2908HD.png' twitter='/images/Search/P_SearchThumb_IN-2908HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-2908/' locationFR='/fr/Outdoor_Cameras/IN-2908/' crumbLabel="IN-2908" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-2908",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-2908",
        "aria-label": "in 2908 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-2908`}</h1>
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6df37e18530400d17c5f8c25017960dd/573d3/IN-2908_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACC0lEQVQoz12S70tTYRTH7z8SBIK+MLM39UqQKCIz1GIzjFJj2ErbstxcOIMVzWmmS/thP/4AsxTNoiAwEFZNHNTSkrGMbW2NhZvNrZHb7v3EvWtjet6c5zznPJ9zeM5XAIjH44TDYRKJDbabJElks1nF52NRFFlZ8WAbHObW8AjBYLCQE+bm3lJaVsahmqNYb9ppv6Cn87KBLqMJp3NBKUyn00pxHhoK/WTo9n3OntdRW3ecnl4LiURSyQkTT58hCAKNpzTojL3sq9pPecUeduwsocvYXQDKU+WB4XAEk/kah2sbaFA10arRsrmZzgFj67/p0F9EdbIFrd7A6TYdVdUH2VW5l7GHj7dMKENli0R+0WcbRN3UTKvmHD+CISanphmfmEKQC/6m01isA5iv96PrNFB3TI35qgV14wkcjncFqGxe7yrOBZdyto8+oF1n4P0HJyWllZxp0yNkstncg0wGfyDAt9XvtGk7GBm9S3nFbmZmnhcW5PF4WXR9LMTR2DpH6lT0D9h59foN408mEURRIitKiNL/rQL3xh7h/rzE2lqUZPKPci+f5+cdxOMbStPlL1+VTS+5l7eoQiiWR/HHbze3exmLpY8b1kE6L3VTX6+m+kANtjtDmK700Nyiwef3I+TkkANKRbqT4fkGsk+lUsRisUJDny/A4ic3zhUXL17OMjU9SzQa5R9Aa07zvZpXdwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6df37e18530400d17c5f8c25017960dd/e4706/IN-2908_Overview_width1650.avif 230w", "/en/static/6df37e18530400d17c5f8c25017960dd/d1af7/IN-2908_Overview_width1650.avif 460w", "/en/static/6df37e18530400d17c5f8c25017960dd/7f308/IN-2908_Overview_width1650.avif 920w", "/en/static/6df37e18530400d17c5f8c25017960dd/e1c99/IN-2908_Overview_width1650.avif 1380w", "/en/static/6df37e18530400d17c5f8c25017960dd/e02f7/IN-2908_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6df37e18530400d17c5f8c25017960dd/a0b58/IN-2908_Overview_width1650.webp 230w", "/en/static/6df37e18530400d17c5f8c25017960dd/bc10c/IN-2908_Overview_width1650.webp 460w", "/en/static/6df37e18530400d17c5f8c25017960dd/966d8/IN-2908_Overview_width1650.webp 920w", "/en/static/6df37e18530400d17c5f8c25017960dd/445df/IN-2908_Overview_width1650.webp 1380w", "/en/static/6df37e18530400d17c5f8c25017960dd/44758/IN-2908_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6df37e18530400d17c5f8c25017960dd/81c8e/IN-2908_Overview_width1650.png 230w", "/en/static/6df37e18530400d17c5f8c25017960dd/08a84/IN-2908_Overview_width1650.png 460w", "/en/static/6df37e18530400d17c5f8c25017960dd/c0255/IN-2908_Overview_width1650.png 920w", "/en/static/6df37e18530400d17c5f8c25017960dd/b1001/IN-2908_Overview_width1650.png 1380w", "/en/static/6df37e18530400d17c5f8c25017960dd/573d3/IN-2908_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6df37e18530400d17c5f8c25017960dd/c0255/IN-2908_Overview_width1650.png",
              "alt": "INSTAR IN-2908",
              "title": "INSTAR IN-2908",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-2908 is the first in the 290X-series to feature a remotely controllable focal length. Many more Upgrades makes this camera a worthy successor of the IN-2907. Among those upgrades you`}{`'`}{`ll find an IR cut filter, an improved sensor and higher night vision capabilities. It also features Audio I/O for the addition of optional speakers or microphones (e.g. IN-Mikro 380) to use the camera as a 2-way communication system. The IN-2908 is the perfect surveillance camera for businesses and private households. The casing out of high quality aluminium guarantees maximum durability and is weather proof for outdoor use. Thanks to its 3x optical zoom lense you will be able to enlarge as if you were taking the camera closer to the monitored scenery. Unlike digital zoom this optical zoom lens delivers crystal clear pictures.`}</p>
    <p>{`This camera model can easily be connected to your network by using its `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{` 54MBit (802.11b/g/n) Wifi module `}</Link>{` or through the 100MBit network interface. This will ensure a fast stream of the cameras video.`}</p>
    <p>{`For surveillance purposes, the IN-2908 is ready for implementation to your existing system. Home Automation Systems as well as NAS Systems like `}<Link to="/Software/Other_Platforms/Synology/" mdxType="Link">{`Synology`}</Link>{` or `}<Link to="/Software/Other_Platforms/QNAP/" mdxType="Link">{`QNAP`}</Link>{` are have integrated our cameras into their products. You will find profiles to the corresponding cameras within the systems software. But also as a stand-alone device it offers you a number of alarming features like `}<Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link">{`Email Notification`}</Link>{` or compatibility with `}<Link to="/Products/IN-Motion/" mdxType="Link">{`signalling devices`}</Link>{`.`}</p>
    <Link to="/Outdoor_Cameras/IN-2908/Technical_Specifications/" mdxType="Link"><EuiButton fill color="primary" mdxType="EuiButton">Technical Specifications</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Nightvision with 30 Infrared LED´s @ 850nm (barely visible for the human eye) for distances up to 12 - 20m`}</li>
      <li parentName="ul">{`High quality IP65 weatherproof aluminium housing`}</li>
      <li parentName="ul">{`Alarm I/O for external sensors (e.g. `}<Link to="/Products/IN-Motion/" mdxType="Link">{`motion`}</Link>{` or smoke detectors) and receivers (e.g. house automations)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/720p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS (`}{`"`}{`Internet`}{`"`}{`) Address`}</Link>{` `}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      